import React from 'react';
import './Menu.scss';
import {
    Link
  } from "react-router-dom";
// @ts-ignore
function Menu(props){
    let locations = JSON.parse(props.locations);
    // @ts-ignore
    let isManager = locations.filter( (location) => {
        if (location.manager) {
            return location;
        }
    });

    if (isManager.length > 0 || props.superuser) {
        return  <ul className="app-menu">
                <li><Link to="/">My Details</Link></li>
                <li><Link to="/manager">Manager</Link></li>
            </ul>
    } else {
        return  <ul className="app-menu">
            <li><Link to="/">My Details</Link></li>
        </ul>
    }

      
}

  export default Menu;