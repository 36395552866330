import React from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { 
  NonIdealState, 
} from "@blueprintjs/core";

import { 
  Card, 
  Button,
  Icon,
  Loader,
  Input,
  Dropdown,
  Modal, 
  Header, 
 } from 'semantic-ui-react'

import './Manager.scss';
import {user} from "../../interfaces/user";
import {location} from "../../interfaces/location";
import classNames from "classnames";
import UserOverlay from "../../components/UserOverlay/UserOverlay";
import { Route, Redirect } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// @ts-ignore
const Manager = (props) => {

  console.log(props);

  const isManager = JSON.parse(props.locations).filter((location:any) => {
    if(props.superuser){
      return true;
    }
    if(location.manager) {
      return location;
    }
  });


  const formatLocation = function(locations: [location]) {
    locations.map((location) => {
      let loc:any = {};
      loc.key = location.id;
      loc.text = location.name;
      loc.value = location.id;
      newLocations.push(loc);
      return location
    });

    return locations
  }


  let locations = JSON.parse(props.locations);
  let newLocations:any[] = [{key:"0", text:"Please choose", value:"0"}];
  let superUser = props.superuser;

  locations = locations.filter( (location: location) => {
    if (location.manager) {
        return location;
    }
  });

  formatLocation(locations);
  
  async function resendPassword(userId: string) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        },
      data: {'userId': userId},
      url: `${BASE_URL}users/resendpassword`,
    };
    //@ts-ignore
    let resp = await axios(options);
    if (!resp.data.errorType) {
      handleCloseResend();
    } else {
      handleCloseResend();
      setShowResendError(true);
    }
  }

  async function searchList(userId: string) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        },
      data: {'search': userId},
      url: `${BASE_URL}users/search`,
    };
    //@ts-ignore
    const resp = await axios(options);
    if(resp.data.statusCode === 200) {
      setUsers(resp.data.body.message);
    } else {
      setUsers([]);
    }
    setLoad(true);
  }

  async function fetchList(group: string, value:string) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        },
      data: {'group': group, 'search': value},
      url: `${BASE_URL}users/list`,
    };
    //@ts-ignore
    const resp = await axios(options);
    if(resp.data.statusCode === 200) {
      setUsers(resp.data.body.message);
    } else {
      setUsers([]);
    }
    setLoad(true);
  }

  const [users, setUsers] = React.useState([]);
  const [loaded, setLoad] = React.useState(true);
  const [showReset, setShowReset] = React.useState(false);
  const [showResend, setShowResend] = React.useState(false);
  const [formLocation, setFormLocation] = React.useState('');
  const [formValue, setFromValue] = React.useState('')
  const [activeUser, setActiveUser] = React.useState('');
  const [resendError, setShowResendError] = React.useState(false);
  const [formSearchValue, setFormSearchValue] = React.useState("");
  const [userLocations, setUserLocations] =React.useState([]);

  const closeResendError = () => setShowResendError(false);
  const handleCloseReset = () => setShowReset(false);
  const handleShowReset = (userID:string) => {
    setActiveUser(userID);
    setShowReset(true);
  };
  const handleCloseResend = () => setShowResend(false);
  const handleSearchChangeValue = (event:any) => {
    setFormSearchValue(event.target.value);
  }
  const handleShowResend = (userID:string) => {
    setActiveUser(userID);
    setShowResend(true)
  }
  const handleChangeLocation = (event:any, data:any) => {
    setFormLocation(data.value);
  };
  const handleChangeValue = (event:any) => {
    setFromValue(event.target.value);
  };
  const handleFromSubmit = () => {
    setLoad(false);
    fetchList(formLocation, formValue);
  }
  const handleSearchFromSubmit = () => {
    setLoad(false);
    searchList(formSearchValue);
  }
  const loadedState = () => {
    setLoad(!loaded);
  };
  const LoadingState = () => {
    return (
      <div className="Dashboard">
        <Loader size="massive" content="Loading"/>
      </div>
    )
  };
  const NoContent = () => {
    return (
      <NonIdealState
        icon="search"
        title="No results found"
        description="Please try searching again"
      />
    )
  }

  const UserList = () => {
    return (
      <div>
        {users.map((user:user, index) => ( 

        <Card key={user.vals["custom:accountID"]} fluid>
        <Card.Content>
          <Card.Header>
            Name:{' '}
            {user.vals.given_name}
            {' '}
            {user.vals.family_name}
          </Card.Header>

          <Card.Description>
            <p>
              Colleague ID:
              {' '} 
              {user.vals["custom:accountID"]}
            </p>
            <p>
              {!user.vals.email.includes("temp-") ?  user.vals.email : "User does not have a registered email." } 
            </p>
          </Card.Description>
        </Card.Content>
        <Card.Content  className="card-mod">
            {user.Enabled ?  <Actions vals={user.vals}></Actions>  : <p className="note">User has been disabled, please contact <a href="mailto:IT.Support@hc-one.co.uk">IT.Support@hc-one.co.uk</a></p>  }
        </Card.Content>
        </Card>
        ))}
      </div>
    )
  }

  const Actions = (props:any) => {
    return (
      <div className='ui two buttons fullwidth controls'>
        <Button 
        onClick={() => handleShowReset(props.vals["custom:accountID"])}>
          Reset password
        </Button>
        {!props.vals.email.includes("temp-") ?  <Button  onClick={() => handleShowResend(props.vals["custom:accountID"])}>Resend password</Button>  : " " }
      </div>
    )
  }

  const UserState = () => {
    return (
      <div className="Dashboard">
        <div className="container">
          {users.length > 0 ? <UserList /> : <NoContent /> }
        </div>
       

        <Modal
          basic
          open={showResend}
          size='small'
        >
          <Header icon>
            <Icon name='send' />
            Resend users password
          </Header>
          <Modal.Content>
            <p className="modal-center">
            Are you sure you want to resend this user's account password to their email?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' inverted onClick={() => handleCloseResend()}>
              <Icon name='cancel' /> Cancel
            </Button>
            <Button color='green' inverted onClick={() => resendPassword(activeUser)}>
              <Icon name='send' /> Resend password
            </Button>
          </Modal.Actions>
        </Modal>


        <Modal
          basic
          open={resendError}
          size='small'
        >
          <Header icon>
            <Icon name='exclamation triangle' />
            Resend users password
          </Header>
          <Modal.Content>
            <p className="modal-center">
            Could not resend the users log in code, this is usually due to a user having not logged into their account before.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' inverted onClick={closeResendError}>
              <Icon name='send' /> OK
            </Button>
          </Modal.Actions>
        </Modal>

      </div>
    )
  }

  return !superUser ? (
    <div className="Manager">
      {isManager.length > 0 ? "" : <Redirect to="/" />  }
      <div className="search-box">
        <h3>Your Management Locations</h3>
        <Input
          label={<Dropdown 
            defaultValue='0' 
            options={newLocations} 
            onChange={handleChangeLocation}
            
            />}
          labelPosition='right'
          placeholder="Colleague's lastname"
          onChange={handleChangeValue}
          key="textinput"
          type="text"
        />
        <Button
          onClick={handleFromSubmit}
          size="large"
          disabled={(formLocation === '') || (formValue === '')}
        ><Icon name='search' />
          Search</Button>
      </div>
      {loaded ? <UserState /> :  <LoadingState />}
      <UserOverlay key="UserOverlay" activeUser={activeUser} showReset={showReset}  close={handleCloseReset}/>
    </div>
    ) : (
      <div className="Manager">
        {isManager.length > 0 ? "" : <Redirect to="/" />  }
        <div className="search-box">
          <h3>Your Super User Search</h3>
            <div>
            <Input
              placeholder="Colleagues account number"
              onChange={handleSearchChangeValue}
              key="textinput"
              type="text"
              className="super-search"
            />
            <Button
              onClick={handleSearchFromSubmit}
              size="large"
              disabled={formSearchValue === ''}
            ><Icon name='search' />
          Search</Button>
            </div>
        </div>
        {loaded ? <UserState /> :  <LoadingState />}
        <UserOverlay key="ManagerOverlay" activeUser={activeUser}  showReset={showReset} close={handleCloseReset}/>
      </div>
    );
  }

  export default Manager;