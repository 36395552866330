import React from 'react';
import { 
    Button,
    Input,
    Modal,
    Header,
    Icon,
    Radio

   } from 'semantic-ui-react'
import { Auth } from 'aws-amplify';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// @ts-ignore
function UserOverlay(props) {

    const [passState, setPass] = React.useState("password");
    const [newPass, setNewPass] = React.useState("");
    const [newPassConfirm, setNewPassConfirm] = React.useState("");
    const [disabledButton, setDisableButton] = React.useState(true);

    const passwordPolicy = function(password: string, newpassword: string) {
          if(password !== newpassword) {
            return false;
          }
          if(password.length >= 16) {
            return true;
          } else {
            return false;
          }
      }

    const handlePassChangeValue = (event:any) => {
        setNewPass(event.target.value);
        if(passwordPolicy(event.target.value, newPassConfirm)) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    };


    const handlePassConfirmChangeValue = (event:any) => {
        setNewPassConfirm(event.target.value);
        if(passwordPolicy(event.target.value, newPass)) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    };

    const handlePassChange = () => {
        if(passState === "password") {
          setPass("text");
        } else {
          setPass("password");
        }
    };

    async function setPassword(targetUser:string, newPass:string, newPassConfirm:string, close:any) {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const options = {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
            },
          data: {'newpass': newPass, 'userId': targetUser},
          url: `${BASE_URL}users/setpass`,
        };
    
        if(newPass === newPassConfirm && newPass !== "") {
          //@ts-ignore
          const resp = await axios(options);
          if (resp.data.statusCode === 200) {
            handlePassChange();
            close();
          }
          // else {
          //   console.log("something went wrong");
          // }
        }
        // else {
        //   console.log("passwords don't match");
        // }
    };

    return (
        <Modal
            closeIcon
            open={props.showReset}
            onClose={() => props.close("close")}
            size="tiny"
        >
          <Header icon='lock' content="Reset user's password" />
          <Modal.Content className="input-group">
            <p>You are about to update a colleagues password, it is advised that they log in to this website and reset their password as soon as possible.</p>
            <Input
                size="large"
                type={passState} 
                onChange={handlePassChangeValue} 
                key="password" 
                placeholder="User's new password" 
            />

            <Input
                size="large"
                type={passState} 
                onChange={handlePassConfirmChangeValue} 
                key="passwordConfirm" 
                placeholder="Confirm user's new password" 
            />

            <p className="explanation">Passwords must be a minimum of 16 characters.</p>

            <div className="controls">
                <Radio 
                    className="show-pass"
                    toggle 
                    onChange={handlePassChange} 
                    label='Show password'
                />
                <Button 
                    className="change-button"
                    disabled={disabledButton}
                    size="large"
                    onClick={() => setPassword(props.activeUser, newPass, newPassConfirm, props.close)}
                >
                    {disabledButton ? <Icon name='lock' /> : <Icon name='lock open' />} 
                    Change user's password
                </Button>
            </div>
            </Modal.Content>
            <Modal.Actions>
              <Button color='green' onClick={() => props.close("close")}>
                <Icon name='close' /> Close
              </Button>
            </Modal.Actions>
        </Modal>
    )   
};

export default UserOverlay;


