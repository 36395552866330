import React from 'react';
import { 
    Button,
    Input,
    Modal,
    Header,
    Icon,
    Radio

   } from 'semantic-ui-react'
import { Auth } from 'aws-amplify';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// @ts-ignore
function PasswordError(props) {

    const [passState, setPass] = React.useState("password");
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [newPass, setNewPass] = React.useState("");
    const [newPassConfirm, setNewPassConfirm] = React.useState("");
    const [disabledButton, setDisableButton] = React.useState(true);

    
    return (
            <Modal
                closeIcon
                open={props.showError}
                onClose={() => props.close("close")}
                size="tiny"
            >
                <Header icon='lock' content="There was a problem with your request" />
                <Modal.Content >
                    <p>Please check that your current password is correct and that any new password meets our password policy</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={() => props.close("close")}>
                        <Icon name='close' /> Close
                    </Button>
                </Modal.Actions>
            </Modal>
    )   
};

export default PasswordError;


