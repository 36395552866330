import React from 'react';
import './App.scss';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Menu from '../components/Menu/Menu'
import Home from './Home/Home';
import Manager from './Manager/Manager';
import '../semantic-ui-css/semantic.min.css';
import { Button } from 'semantic-ui-react';
import axios from 'axios';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_CLIENTID,
  },
});

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CC_URL = process.env.REACT_APP_CC_URL;
let set = false;

const App: React.FunctionComponent = () => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<any | undefined>();
  const [superUser, setSuperUser] = React.useState(false);

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      //@ts-ignore
      if(authData && authData.signInUserSession && authData.signInUserSession && authData.signInUserSession.accessToken && authData.signInUserSession.accessToken.payload && authData.signInUserSession.accessToken.payload["cognito:groups"]) {
         //@ts-ignore
        let groups = authData.signInUserSession.accessToken.payload["cognito:groups"];
        if(groups.includes("superuser")) {
          setSuperUser(true);
        } else {
          setSuperUser(false);
        }

        recordUser();
      }
        setAuthState(nextAuthState);
        setUser(authData)
    });
  }, []);

  const signOut = async () => {
    const user = await Amplify.Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const userId = user.username;
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        },
      data: {'userId': userId},
      url: `${BASE_URL}users/logout`,
    };

    try {
      //@ts-ignore
      await axios(options);
      await Amplify.Auth.signOut();
      set = false;
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  async function recordUser() {
    if(!set) {
      set = true;
      const user = await Amplify.Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      const userId = user.username;
      const options = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
          },
        data: {'userId': userId},
        url: `${BASE_URL}users/login`,
      };

      //@ts-ignore
      setTimeout(function(){ axios(options); }, 5000);
    }
  };



  return authState === AuthState.SignedIn && user && user.attributes && user.attributes.email ? (
    <div className="App">
      <Router key="routerOut">
        <div className="app-actual">
          <div className="container-app">
            <div className="details">
             <p>Hi {user.attributes.given_name} 
             {" "}
              <Button onClick={signOut}  size="small">Sign Out</Button>
            </p>
            </div>
            <div className="brand">
              <img src="hc-one-logo.png" width="280" height="130" alt="HC One"/>
            </div>

          </div>

          <div className="menu">
            <Menu locations={user.attributes['custom:locations']} superuser={superUser}/>
          </div>
      
          <Switch key="routerSwitch">
            <Route exact path="/">
              <Home user={user}/>
            </Route>
            <Route path="/manager" key="managerRoute">
              <Manager key="managerView" locations={user.attributes['custom:locations']} superuser={superUser}/>
            </Route>
          </Switch>
        </div>
      </Router>

      <div className="footer">
        <p><a href={CC_URL}>Colleague Connect Link</a></p>
        <p><strong>HC-One</strong> Southgate House, Archer Street, <br/> Darlington, County Durham, DL3 6AH</p>
      </div>
    </div>
  ) : (
    <div className="sign-in">
      <div className="holder">
        <AmplifyAuthenticator>
          <AmplifySignIn 
            slot="sign-in"
            hideSignUp={true}
            headerText="Sign in to your identity"
            
            ></AmplifySignIn>
        </AmplifyAuthenticator>
        <p className="hint">Passwords must be a minimum of 16 characters.</p>
      </div>
    </div>
  );

};

export default App;
