import React from 'react';
import { Auth } from 'aws-amplify';

import { 
  Button, 
  Icon, 
  Modal, 
  Header, 
  Card
} from 'semantic-ui-react';
import './Home.scss';
import ResetMyPassword from "../../components/UserOverlay/ResetMyPassword";
import PasswordError from "../../components/UserOverlay/PasswordError";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

 // @ts-ignore
const Home = ({user}) => {

  const [passState, setPass] = React.useState("password");
  const [openAlert, setOpen] = React.useState(false);
  const [showReset, setShowReset] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState('');

  const CC_URL = process.env.REACT_APP_CC_URL;

  const handlePassChange = () => {
    if(passState === "password") {
      setPass("text");
    } else {
      setPass("password");
    }
  }

  const signOut = () => {
    Auth.signOut();
  }


  const handleShowReset = (userID:string) => {
    setActiveUser(userID);
    setShowReset(true);
  };

  const handleCloseReset = (val:string) => {
    if(val === "close") {
      setOpen(false);
      setShowReset(false);
      setShowError(false);
    }

    if(val === "error") {
      setShowError(true);
      setOpen(false);
      setShowReset(false);
    }

    if(val === "logout") {
      setShowError(false);
      setShowReset(false);
      setOpen(true);
    }

  }


  const Actions = (props:any) => {
    return (
      <div className='ui two buttons fullwidth controls'>
        <Button 
        onClick={() => handleShowReset(props.vals["custom:accountID"])}>
          Reset password
        </Button>
      </div>
    )
  }

    return (
      <div className="content-area">
        <Card key={user.username} fluid>
          <Card.Content>
            <Card.Header>
              Name:{' '}
              {user.attributes.given_name}
              {' '}
              {user.attributes.family_name}
            </Card.Header>

            <Card.Description>
              <p>
                Colleague ID:
                {' '} 
                {user.attributes["custom:accountID"]}
              </p>
              <p>
                {!user.attributes.email.includes("temp-") ?  user.attributes.email : "User does not have a registered email." } 
              </p>
            </Card.Description>
          </Card.Content>
          <Card.Content  className="card-mod">
              <Actions vals={user.attributes}></Actions> 
          </Card.Content>
        </Card>

        <div className="sign-post">
          <h3>Looking for Colleague Connect?</h3>
          <div className="items">
            <div className="item">
              <a href={CC_URL}>
                <img src="colleague-connect-logo.png" />
              </a>
            </div>
          </div>
        </div>
        <ResetMyPassword key="UserOverlay" activeUser={activeUser} showReset={showReset}  close={handleCloseReset}/>
        <PasswordError showError={showError}  close={handleCloseReset}/>
        <Modal
          basic
          onClose={() => signOut()}
          open={openAlert}
          size='small'
        >
          <Header icon>
            <Icon name='sign-out' />
            Your password has been changed
          </Header>
          <Modal.Content>
            <p className="modal-center">
              You'll now be logged out where you can log back in with your new password.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' inverted onClick={() => signOut()}>
              <Icon name='sign-out' /> Log Out
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }

  export default Home;